import React, { useState, useEffect } from "react";
import { useParams } from "@reach/router";
import { getAdSpacesThunk, setAdSpacesConsultationDates } from "../../redux/actions/adspaces-actions";
import { formatDateUtc, dateDiff, add, isDateValid, dateWithoutTimezone } from "../../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";

import SideMenuComponent from "../../components/asset-advertising/side-menu";

function SideMenu({ longTermCheckedChange, ...rest }) {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const params = useParams();
  const [isLongTermChecked, setLongTermChecked] = useState(false);
  const adSpacesConsultation = useSelector((state) => state.adspaces.adSpacesConsultation);
  const adSpacesConsultationDates = useSelector((state) => state.adspaces.adSpacesConsultationDates);
  const adSpacesConsultationUnavailableDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationUnavailableDates,
  );
  const assets = useSelector((state) => state.misc.assets);
  const flagLongTermBooking = useSelector((state) => state.misc.featureFlags["flag.ops.long_term_bookings"]);

  const [asset, setAsset] = useState();

  useEffect(() => {
    const asset = assets.find((a) => a.id === parseInt(params.assetId));

    if (asset) {
      setAsset(asset);
    }
  }, [assets]);

  const handleOnDateChange = (dates) => {
    const start = dates[0];
    const end = dates[1];
    const diff = dateDiff(start, end);
    let canSelectDate = true;

    if (isLongTermChecked) {
      const unavailableDatesList = Object.keys(adSpacesConsultationUnavailableDates);
      const blockedDates = unavailableDatesList.map((date) => dateWithoutTimezone(date));
      if (blockedDates.length) {
        const farthestDateBlocked = new Date(Math.max(...blockedDates));
        for (let i = 0; i <= diff; i++) {
          const date = add(start, i, "day").format("YYYY-MM-DD");
          if (new Date(date) <= farthestDateBlocked) {
            canSelectDate = false;
          }
        }
      }
    } else {
      for (let i = 0; i <= diff; i++) {
        const date = add(start, i, "day").format("YYYY-MM-DD");
        if (adSpacesConsultationUnavailableDates[date]) {
          canSelectDate = false;
        }
      }
    }

    if (canSelectDate) {
      dispatch(setAdSpacesConsultationDates(dates));
    } else {
      dispatch(setAdSpacesConsultationDates([]));
    }
  };

  const handleAsk = () => {
    const isStartDateValid = isDateValid(adSpacesConsultationDates[0]);
    const isEndDateValid = isDateValid(adSpacesConsultationDates[1]);
    const isFormValid = isLongTermChecked ? isStartDateValid : isStartDateValid && isEndDateValid;

    if (isFormValid) {
      let searchParams = "";
      if (!isLongTermChecked) {
        searchParams += `&end=${formatDateUtc(adSpacesConsultationDates[1], "YYYY-MM-DD")}`;
      } else {
        searchParams += "&long_term=true";
      }

      navigate(
        `/consult-adspace/${asset.id}?start=${formatDateUtc(
          adSpacesConsultationDates[0],
          "YYYY-MM-DD",
        )}&adspaces=${adSpacesConsultation.map((ad) => ad.id).join(",")}${searchParams}`,
      );
    }
  };

  const handleLongTermCheckedChange = (isChecked) => {
    setLongTermChecked(isChecked);
    longTermCheckedChange(isChecked);
  };

  return (
    <SideMenuComponent
      adSpacesSelecteds={adSpacesConsultation}
      adSpacesConsultationUnavailableDates={adSpacesConsultationUnavailableDates}
      dates={adSpacesConsultationDates}
      onDateChange={handleOnDateChange}
      handleAsk={handleAsk}
      flagLongTermBooking={flagLongTermBooking}
      longTermCheckedChange={handleLongTermCheckedChange}
      {...rest}
    />
  );
}

export default SideMenu;
