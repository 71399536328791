import { Box, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Image from "material-ui-image";
import React, { useEffect, useState } from "react";
// My Components
import LeftArrow from "../../icons/left-arrow";
import RightArrow from "../../icons/right-arrow";
// theme
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  containerImages: {
    position: "relative",
    height: "600px",
    width: "100%",
    backgroundColor: "transparent",
    border: "transparent",
    outline: "none",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: "35vh",
      maxWidth: "100%",
      width: "100vw",
      padding: 0,
    },
  },
  text: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  optionsContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  option: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  selectedOption: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  arrow: {
    height: 15,
    cursor: "pointer",
  },
  arrowLeft: {
    left: "30px",
    [theme.breakpoints.down("md")]: {
      top: "calc(50% - 25px)",
      left: 5,
      height: 50,
      zIndex: 999,
    },
  },
  arrowRight: {
    right: "30px",
    [theme.breakpoints.down("md")]: {
      top: "calc(50% - 25px)",
      right: 5,
      height: 50,
      zIndex: 999,
    },
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "25%",
  },
  arrowBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.white,
    opacity: "0.7",
    borderRadius: "50%",
    width: 48,
    height: 48,
    cursor: "pointer",
    transition: "0.3s all",
    "&:hover": {
      opacity: "1",
    },
  },
  closeIconBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.white,
    opacity: "0.7",
    borderRadius: "50%",
    width: 32,
    height: 32,
    cursor: "pointer",
    transition: "0.3s all",
    zIndex: 999,
    "&:hover": {
      opacity: "1",
    },
  },
}));

const CarouselImage = ({ images = [], onClose = () => {} }) => {
  const classes = useStyles(theme, images.length);
  const [keyPressed, setKeyPressed] = useState({
    key: "",
    shouldExecute: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleNextImage = () => {
    setSelectedIndex((selectedIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else {
      setSelectedIndex(images.length - 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0]?.clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0]?.clientX);
  };

  const handleTouchEnd = () => {
    const draggedDistance = touchStart - touchEnd;
    if (Math.abs(draggedDistance) > 100 && touchEnd !== 0) {
      draggedDistance < 0 ? handlePreviousImage() : handleNextImage();
    }
    setTouchEnd(0);
    setTouchStart(0);
  };

  useEffect(() => {
    function keyDown(e) {
      setKeyPressed({ key: e.key, shouldExecute: true });
    }
    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, []);

  useEffect(() => {
    if (keyPressed.shouldExecute) {
      if (keyPressed.key === "ArrowRight") {
        handleNextImage();
      }
      if (keyPressed.key === "ArrowLeft") {
        handlePreviousImage();
      }
      setKeyPressed({ ...keyPressed, shouldExecute: false });
    }
  }, [keyPressed]);

  return (
    <Box
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      display="flex"
      flexDirection="column"
      className={classes.containerImages}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%" pr={1} pt={1}>
        <Box onClick={onClose} className={classes.closeIconBackground}>
          <CloseIcon style={{ width: "16px", height: "16px" }} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        width="100%"
        pr={1}
        pl={1}
        style={{ transform: "translateY(-20px)" }}
      >
        <Box onClick={handlePreviousImage} className={classes.arrowBackground}>
          <LeftArrow style={{ width: "16px", height: "16px" }} />
        </Box>
        <Box onClick={handleNextImage} className={classes.arrowBackground}>
          <RightArrow style={{ width: "16px", height: "16px" }} />
        </Box>
      </Box>
      <Image
        src={images[selectedIndex] ? images[selectedIndex]?.file_url || images[selectedIndex]?.url : "/placeholder.png"}
        style={{
          height: "100%",
          padding: "0",
          width: "100%",
          zIndex: -5,
          position: "absolute",
          userSelect: "none",
          backgroundColor: "transparent",
        }}
        imageStyle={{
          objectFit: "cover",
          height: "100%",
          width: "100%",
          userSelect: "none",
        }}
      />
    </Box>
  );
};

export default CarouselImage;
