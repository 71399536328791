import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CalendarIcon({ fill = "#000000", ...rest }) {
  return (
    <SvgIcon
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill={fill}
      {...rest}
    >
      <path
        d="M70.1818 19.7273V15H65.4545V19.7273H52.4545V15H47.7273V19.7273H34.7273V15H30V19.7273H17V22.0909V84.7273H38.8636H75.5C76.8 84.7273 77.9818 84.3727 78.9273 83.5455C79.6364 82.9545 80.1091 82.3636 80.5818 81.6545C81.1727 80.5909 81.5273 79.5273 81.7636 78.4636C82 77.4 82 76.2182 82 75.2727V38.6364V19.7273H70.1818ZM34.7273 28V24.4545H47.7273V28H52.4545V24.4545H65.4545V28H70.1818V24.4545H77.2727V32.7273H21.7273V24.4545H30V28H34.7273ZM77.2727 75.2727C77.2727 76.6909 77.0364 78.2273 76.6818 79.0545C76.4455 79.5273 76.3273 79.7636 76.0909 79.8818C75.9727 80 75.8545 80 75.5 80H38.8636H21.7273V37.4545H77.2727V38.6364V75.2727Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
