import SvgIcon from '@material-ui/core/SvgIcon';
import React from "react";

export default function BackLongIcon({ fill = "black", ...rest }) {
  return (
    <SvgIcon width="28" height="12" viewBox="0 0 28 12" fill="none" {...rest}>
      <path d="M27.7536 4.85425H1.58435V6.92924H27.7536V4.85425Z" fill={fill}/>
      <path d="M6.98445 11.4871L0.542589 6.72116C-0.181287 6.18581 -0.180862 5.34752 0.543858 4.81277L7.04903 0L9.19153 1.42818L3.32666 5.76755L9.12653 10.0589L6.98445 11.4871Z" fill={fill} />
    </SvgIcon>
  );
}
