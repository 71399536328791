import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function TrashIcon({ fill = "#333333", width = 15, height = 18, ...rest }) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 190 190"
      fill="none"
      {...rest}
    >
      <path
        d="M156.5 44.2H139.3V19.5C139.3 17.8 138 16.5 136.3 16.5H53.2C51.5 16.5 50.2 17.8 50.2 19.5V44.2H33.5C31.8 44.2 30.5 45.5 30.5 47.2V78.4C30.5 80.1 31.8 81.4 33.5 81.4H45.7L50.3 170.7C50.4 172.3 51.7 173.5 53.3 173.5H136.5C138.1 173.5 139.4 172.2 139.5 170.7L144.1 81.4H156.5C158.2 81.4 159.5 80.1 159.5 78.4V47.2C159.5 45.5 158.2 44.2 156.5 44.2ZM59.7 26H129.8V44.1H59.7V26ZM130.3 164H114.1L116.5 95L107 94.8L104.6 164.2H85.4L83 94.8L73.5 95L75.9 164H59.2L55 81.4H134.3L130.3 164ZM150 71.9H144.5H45.2H40V53.7H50.2H139.3H150V71.9Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

