import React, { useState } from "react";
import { useSelector } from "react-redux";
import MapCardComponent from "../../components/google-maps/AdSpaceListCard";

const MapCard = () => {
  const space = useSelector((state) => state.googleMaps.space);
  const status = useSelector((state) => state.googleMaps.spaceStatus);

  return <MapCardComponent space={space} status={status} />;
};

export default MapCard;
