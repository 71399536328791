import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import theme from "../../theme";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";
import CalendarIcon from "../../icons/calendar-icon";
import TrashIcon from "../../icons/trash-icon";
import BackLongIcon from "../../icons/back-long-icon";
import AdSpaceAvailableDatesCalendar from "../../containers/ad-space-available-dates-calendar";
import AdSpaceSelectedsModal from "../../containers/asset-advertising/selecteds-ad-space-modal";

const useStyles = makeStyles((theme) => ({
  menu: {
    width: "300px",
    height: "auto",
    padding: theme.spacing(2),
  },
  calendarBox: {
    width: "100%",
    height: "300px",
    border: theme.borders[2],
  },
  datesBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "40px",
    border: theme.borders[1],
    marginTop: theme.spacing(2),
  },
  dateBoxIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "100%",
    borderRight: theme.borders[1],
  },
  rightArrow: {
    transform: "rotate(180deg)",
    width: 21,
    height: 20,
  },
  longTermInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "16px",
    marginTop: "16px",
  },
}));

function SideMenu({
  adSpacesSelecteds = [],
  adSpacesConsultationUnavailableDates = {},
  dates = [],
  onDateChange = () => {},
  handleAsk = () => {},
  flagLongTermBooking,
  longTermCheckedChange = () => {},
  ...rest
}) {
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);
  const [isLongTermChecked, setLongTermChecked] = useState(false);
  const { i18n } = useLingui();

  const handleResetDates = () => {
    onDateChange([null, null]);
  };

  const handleCheckboxChange = (event) => {
    setLongTermChecked(event.target.checked);
    longTermCheckedChange(event.target.checked);
    onDateChange([null, null]);
  };

  return (
    <Paper elevation={2} {...rest}>
      <AdSpaceSelectedsModal open={open} onClose={() => setOpen(false)} showAdSpaceCardAction />
      <Box className={classes.menu}>
        <AdSpaceAvailableDatesCalendar
          startDate={dates[0]}
          endDate={dates[1]}
          adSpacesConsultationUnavailableDates={adSpacesConsultationUnavailableDates}
          onChange={onDateChange}
          isLongTermChecked={isLongTermChecked}
          calendarProps={{
            selectRange: !isLongTermChecked,
          }}
        />
        <Box className={classes.datesBox}>
          <Box className={classes.dateBoxIcon}>
            <CalendarIcon style={{ width: "35px", height: "35px" }} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            style={{ textTransform: "uppercase" }}
          >
            <Typography variant="subtitle1">
              {`${isDateValid(dates[0]) ? formatDateUtc(dates[0], "DD/MM/YYYY") : i18n._(t`Ingreso`)}`}
            </Typography>
            {!isLongTermChecked && (
              <>
                <Box ml={1} mr={1}>
                  <BackLongIcon className={classes.rightArrow} />
                </Box>
                <Typography variant="subtitle1" s>
                  {`${isDateValid(dates[1]) ? formatDateUtc(dates[1], "DD/MM/YYYY") : i18n._(t`Salida`)}`}
                </Typography>
              </>
            )}
          </Box>
        </Box>

        {flagLongTermBooking && (
          <Box className={classes.longTermInputContainer}>
            <Checkbox
              style={{
                color: "rgba(0, 0, 0, 0.54)",
              }}
              checked={isLongTermChecked}
              onChange={handleCheckboxChange}
              color="secondary"
              inputProps={{ "aria-label": "checkbox" }}
            />
            <Typography variant="body1">
              <Trans>Estadía prolongada</Trans>
            </Typography>
          </Box>
        )}

        <Box width="100%">
          <Box
            onClick={handleResetDates}
            style={{ cursor: "pointer" }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={1}
            mb={1}
          >
            <Typography variant="body1" style={{ textDecoration: "underline" }}>
              <Trans>Limpiar fechas</Trans>
            </Typography>
            <TrashIcon style={{ width: "11px", marginLeft: theme.spacing(1 / 2) }} />
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
            style={{
              textTransform: "uppercase",
              backgroundColor: theme.palette.secondary,
              color: theme.palette.white,
            }}
          >
            {`${Object.keys(adSpacesSelecteds).length} ${" "}`}
            <Trans>Items seleccionados</Trans>
          </Button>
          <Box mt={2}>
            <Button
              fullWidth
              disabled={!(isDateValid(dates[0]) && isDateValid(dates[1]))}
              onClick={handleAsk}
              variant="contained"
              color="primary"
              style={{ backgroundColor: theme.palette.primary }}
            >
              <Trans>Consultar por proyecto</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default SideMenu;
