import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import { isMobile } from "../../utils";
import { formatCurrencyForTenant } from "../../utils/currencyUtils";
import CarouselImage from "../carousel-image";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "740px",
    maxWidth: "740px",
    height: "520px",
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  cardDesc: {
    padding: theme.spacing(2),
  },
  cardDescWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

function AdSpaceModal({ open, onClose, adSpace, showPrice }) {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const handleClose = (e, r) => {
    if (r === "backdropClick" || r === "escapeKeyDown") {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile()}
      disableScrollLock
      PaperProps={{
        style: {
          maxWidth: "100%",
          padding: 0,
        },
      }}
    >
      <Box className={classes.card}>
        <CarouselImage images={adSpace.attachments} onClose={onClose} />
        <Box className={classes.cardDesc} textAlign="left">
          <Box className={classes.cardDescWrapper}>
            <Typography variant="h1">{adSpace.title}</Typography>
            {showPrice && (
              <Typography variant="subtitle1">
                <Trans>Quincena</Trans>
                {` ${formatCurrencyForTenant(adSpace.fortnight_price)} | ${i18n._(t`Mes`)} ${formatCurrencyForTenant(
                  adSpace.month_price,
                )}`}
              </Typography>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="body1">{adSpace.description}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AdSpaceModal;
