import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import theme from "../../theme";
import CloseIcon from "../../icons/close-icon";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";

import AdSpaceAvailableDatesCalendar from "../../containers/ad-space-available-dates-calendar";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "740px",
    height: "520px",
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  cardDesc: {
    padding: theme.spacing(2),
  },
  cardDescWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  image: {
    position: "relative",
    height: "75%",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    width: 15,
    height: 15,
    zIndex: 999,
    right: 18,
    top: 10,
  },
}));

function CalendarMobileDialog({
  open,
  onClose,
  adSpacesConsultationUnavailableDates,
  adSpacesConsultationDates = ["", ""],
  onDateChange,
}) {
  const classes = useStyles(theme);

  const handleClose = (e, r) => {
    if (r === "backdropClick" || r === "escapeKeyDown") {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={true}
      disableScrollLock
      PaperProps={{
        style: {
          maxWidth: "100%",
          padding: 0,
        },
      }}
    >
      <Box className={classes.card}>
        <Box className={classes.closeIcon} onClick={onClose}>
          <CloseIcon fill={theme.palette.black} width="16px" height="16px" />
        </Box>
        <Box mb={4}>
          <Typography variant="h1">
            <Trans>Seleccionar fechas</Trans>
          </Typography>
        </Box>
        <Box>
          <AdSpaceAvailableDatesCalendar
            adSpacesConsultationUnavailableDates={
              adSpacesConsultationUnavailableDates
            }
            startDate={adSpacesConsultationDates[0]}
            endDate={adSpacesConsultationDates[1]}
            onChange={onDateChange}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="space-evenly" mt={4}>
          <Button onClick={onClose} variant="contained" color="primary">
            <Trans>Aceptar</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CalendarMobileDialog;
