import React from "react";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import { Typography } from "@material-ui/core";
import numbro from "numbro";
import CloseIcon from "../../icons/close-icon";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  mapCard: {
    display: "none",
    flexDirection: "row",
    position: "absolute",
    width: "40%",
    bottom: "-50%",
    left: "30%",
    height: "25%",
    borderRadius: "5px",
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      left: "0",
    },
  },
}));

function MapCard({ space, status }) {
  const classes = useStyles({ ...theme });

  const handleCloseCard =() => {
    const mapCard = document.getElementById("map-card");
    mapCard.style.bottom = "-50%";
    mapCard.style.display = "none";
  }

  return (
    <Box id="map-card" className={classes.mapCard} boxShadow={4}>
      <Image
        disableSpinner
        color={theme.palette.darkGray}
        src={space.img_url ? space.img_url : "/logo.svg"}
        style={{
          height: "100%",
          padding: "0",
          width: "30%",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
        imageStyle={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        width="60%"
        p={1}
      >
        {status === "loading" && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
        {status === "success" && (
          <>
            <Typography variant="subtitle1">{space.title}</Typography>
            {space.display_price && (
              <Typography variant="subtitle1" color="primary">
                Desde {numbro(space.display_price).formatCurrency()}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={1}
        style={{ cursor: "pointer" }}
        onClick={handleCloseCard}
      >
        <CloseIcon width="15px" height="15px" />
      </Box>
    </Box>
  );
}

export default MapCard;
