import React, { useState, useEffect } from "react";
import MobileFloatingMenu from "../../components/asset-advertising/mobile-floating-menu";
import { useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useNavigate } from "/src/hooks/navigation";
import { formatDateUtc } from "../../utils/dateUtils";

export default () => {
  const params = useParams();
  const { navigate } = useNavigate();

  const adSpacesConsultation = useSelector(
    (state) => state.adspaces.adSpacesConsultation
  );

  const adSpacesConsultationDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationDates
  );

  const assets = useSelector((state) => state.misc.assets);

  const [asset, setAsset] = useState();

  useEffect(() => {
    const asset = assets.find((a) => a.id === parseInt(params.assetId));

    if (asset) {
      setAsset(asset);
    }
  }, [assets]);

  const handleAsk = () => {
    if (adSpacesConsultationDates[0] && adSpacesConsultationDates[1]) {
      navigate(
        `/consult-adspace/${asset.id}?start=${formatDateUtc(
          adSpacesConsultationDates[0],
          "YYYY-MM-DD"
        )}&end=${formatDateUtc(
          adSpacesConsultationDates[1],
          "YYYY-MM-DD"
        )}&adspaces=${adSpacesConsultation.map((ad) => ad.id).join(",")}`
      );
    }
  };

  return (
    <MobileFloatingMenu
      adSpacesConsultation={adSpacesConsultation}
      adSpacesConsultationDates={adSpacesConsultationDates}
      handleAsk={handleAsk}
    />
  );
};
