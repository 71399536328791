import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "../../icons/close-icon";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    textAlign: "left",
  },
  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
    color: theme.palette.grey[500],
  },
});

const TenantDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <Box mb={2}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon width="15px" height="15px" />
          </IconButton>
        </Box>
      ) : null}
      <Typography variant="h1">{children}</Typography>
    </DialogTitle>
  );
});

export default TenantDialogTitle;
