import React, { useState, useEffect } from "react";
import Calendar from "../../components/calendar";
import { formatDateUtc, dateWithoutTimezone } from "../../utils/dateUtils";

const SpaceAvailableDatesCalendar = ({
  startDate = "",
  endDate = "",
  onChange = () => {},
  adSpacesConsultationUnavailableDates = {},
  isLongTermChecked = false,
  calendarProps = {},
}) => {
  const [farthestDateBlocked, setFarthestDateBlocked] = useState(null);

  useEffect(() => {
    const unavailableDatesList = Object.keys(adSpacesConsultationUnavailableDates);
    const blockedDates = unavailableDatesList.map((date) => dateWithoutTimezone(date));

    if (blockedDates.length) {
      setFarthestDateBlocked(new Date(Math.max(...blockedDates)));
    }
  }, [adSpacesConsultationUnavailableDates]);

  const handleIsTileDisabled = ({ date }) => {
    if (isLongTermChecked && Object.keys(adSpacesConsultationUnavailableDates).length) {
      return date <= farthestDateBlocked;
    }
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");
    return adSpacesConsultationUnavailableDates[formattedDate] !== undefined;
  };

  return (
    <Calendar
      tileDisabled={handleIsTileDisabled}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      isDouble={false}
      {...calendarProps}
    />
  );
};

export default SpaceAvailableDatesCalendar;
