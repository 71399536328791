import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdSpaceConsultation,
  getAdSpaceConsultUnavailableDatesThunk,
} from "../../redux/actions/adspaces-actions";
import SelectedsAdSpaceModalComponent from "../../components/asset-advertising/remove-selecteds-ad-space-modal";

function SelectedsAdSpaceModal({ open, onClose }) {
  const dispatch = useDispatch();

  const adSpacesConsultation = useSelector(
    (state) => state.adspaces.adSpacesConsultation
  );

  const removeSelectedAdSpaces = (adSpace) => {
    let currentAdSpacesConsultation = adSpacesConsultation.filter(
      (ad) => ad.id !== adSpace.id
    );
    dispatch(setAdSpaceConsultation(currentAdSpacesConsultation));
    dispatch(
      getAdSpaceConsultUnavailableDatesThunk(currentAdSpacesConsultation)
    );
  };

  return (
    <SelectedsAdSpaceModalComponent
      open={open}
      onClose={onClose}
      adSpacesConsultation={adSpacesConsultation}
      removeSelectedAdSpaces={removeSelectedAdSpaces}
    />
  );
}

export default SelectedsAdSpaceModal;
