import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import CalendarIcon from "../../icons/calendar-icon";
import BackLongIcon from "./../../icons/back-long-icon";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
// theme
import theme from "../../theme";
import { Trans } from "@lingui/macro";

import CalendarMobileDialog from "../../containers/asset-advertising/calendar-mobile-dialog";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.white,
    width: "100%",
    zIndex: 99,
  },
  bold: {
    fontWeight: 700,
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

export default ({
  handleAsk = () => {},
  onClick = () => {},
  adSpacesConsultation = [],
  adSpacesConsultationDates = ["", ""],
}) => {
  const classes = useStyles(theme);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const { i18n } = useLingui();

  return (
    mobile && (
      <Box className={classes.container} boxShadow={3}>
        <CalendarMobileDialog open={open} onClose={() => setOpen(false)} />
        <Box
          display="flex"
          flexDirection="row"
          onClick={() => {
            setOpen(true);
          }}
        >
          <CalendarIcon
            style={{
              width: "45px",
              height: "45px",
            }}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="subtitle1">
              {`${
                isDateValid(adSpacesConsultationDates[0])
                  ? formatDateUtc(adSpacesConsultationDates[0], "DD/MM/YYYY")
                  : i18n._(t`INGRESO`)
              }`}
            </Typography>
            <Typography variant="subtitle1">
              {`${
                isDateValid(adSpacesConsultationDates[1])
                  ? formatDateUtc(adSpacesConsultationDates[1], "DD/MM/YYYY")
                  : i18n._(t`SALIDA`)
              }`}
            </Typography>
          </Box>
        </Box>
        <Button
          variant={"contained"}
          color={"primary"}
          disabled={
            !(
              isDateValid(adSpacesConsultationDates[0]) &&
              isDateValid(adSpacesConsultationDates[1])
            )
          }
          onClick={handleAsk}
        >
          <Trans>Enviar Proyecto</Trans>
        </Button>
      </Box>
    )
  );
};
