import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import {
  getAdSpacesThunk,
  setAdSpacesConsultationDates,
} from "../../redux/actions/adspaces-actions";
import { formatDateUtc, dateDiff, add } from "../../utils/dateUtils";
import CalendarMobileDialogComponent from "../../components/asset-advertising/calendar-mobile-dialog";

function CalendarMobileDialog({ open, onClose }) {
  const dispatch = useDispatch();
  const params = useParams();

  const adSpacesConsultationUnavailableDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationUnavailableDates
  );

  const adSpacesConsultationDates = useSelector(
    (state) => state.adspaces.adSpacesConsultationDates
  );

  const handleOnDateChange = (dates) => {
    const start = dates[0];
    const end = dates[1];
    const diff = dateDiff(start, end);
    let canSelectDate = true;
    for (let i = 0; i <= diff; i++) {
      const date = add(start, i, "day").format("YYYY-MM-DD");
      if (adSpacesConsultationUnavailableDates[date]) {
        canSelectDate = false;
      }
    }
    if (canSelectDate) {
      dispatch(setAdSpacesConsultationDates(dates));
    } else {
      dispatch(setAdSpacesConsultationDates([]));
    }
  };

  return (
    <CalendarMobileDialogComponent
      open={open}
      onClose={onClose}
      adSpacesConsultationDates={adSpacesConsultationDates}
      adSpacesConsultationUnavailableDates={
        adSpacesConsultationUnavailableDates
      }
      onDateChange={handleOnDateChange}
    />
  );
}

export default CalendarMobileDialog;
